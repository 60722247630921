import momemt from 'moment';


jQuery( document ).ready(function($) {
    var showpopup = true;

    $('.icon').draggable({ containment: "parent" });
    $('.popup').draggable({ 
      containment: "parent", 
      stack: ".popup" 
    });

    $('.icon:not(.external-link)').on('click', function(e){
        e.preventDefault();
    });
    
    $('.popup-btn').on('click', function(e){
      e.preventDefault();
        let target = $(this).attr('href');
        $(target).removeClass('popup-hide');
        
        $('.popup').css('z-index', 1);
        $(target).css('z-index', 1000);
        let video = $(target).find('video');
        if (video.length){
          video[0].play();
        }
    });

    if(showpopup){
      $('#intro').removeClass('popup-hide');
    }

    $('.newsletter').on('submit', function(e) {
      e.preventDefault();
      const DATA = $(this).serialize();

      $.ajax({
          type: 'POST',
          url: $(this).attr('action'),
          dataType: 'json',
          data: DATA,
          xhrFields: {
              withCredentials: false,
          },
          success: function (data) {
              $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
          },
          error: function (err) {
              console.log(err);
          }
      });
  });

  $('#contest-form').on('submit', function(e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
        type: 'POST',
        url: $(this).attr('action'),
        dataType: 'json',
        data: DATA,
        xhrFields: {
            withCredentials: false,
        },
        success: function (data) {
            $('.contest-inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
        },
        error: function (err) {
            console.log(err);
        }
    });
});

    $('.close-btn').on('click', function(e){
        e.preventDefault();
        let parent = $(this).closest('.popup');
        parent.addClass('popup-hide');
        let video = $(parent).find('video');
        if (video.length){
          video[0].pause();
          video[0].currentTime=0;
        }
    })

    $('.enter-btn').on('click', function(e){
      e.preventDefault();
      $("#screen>.icon").removeClass('hide');
    })

    $('.menu-toggle').on('click', function(e){
      e.preventDefault();
      $('.menu-wrap').toggleClass('open');
    })
    $('.menu-btn:not(.menu-toggle)').on('click', function(){
      $('.menu-wrap').removeClass('open');
    })

    var quizparty = 0;
    var quizplay = 0;
    var quizdream = 0;

    $('#quiz-btn').on('click', function(){
      quizparty = 0;
      quizplay = 0;
      quizdream = 0;
      $('#quiz .question').hide();
      $('#quiz input').prop('checked', false);
      $('#quiz #result>div').hide();
      $('#quiz #q1').fadeIn();
    })

    $('#quiz .next-btn').on('click', function(e){
      e.preventDefault();
      console.log('Next')

      if($(this).hasClass('result-btn')){
        if ($(this).parent().find("input[value='party']").is(':checked')){
          quizparty++;
          $(this).parent().hide();
          if ((quizplay >= quizdream) && (quizplay >= quizparty)){
            $('#quizplay').show();
          } else if ((quizparty >= quizplay) && (quizparty >= quizdream)){
            $('#quizparty').show();
          } else if ((quizdream >= quizplay) && (quizdream >= quizparty)){
            $('#quizdream').show();
          }
          $($(this).attr('href')).fadeIn();
        }
         else if ($(this).parent().find("input[value='play']").is(':checked')){
          quizplay++;
          $(this).parent().hide();
          if ((quizplay >= quizdream) && (quizplay >= quizparty)){
            $('#quizplay').show();
          } else if ((quizparty >= quizplay) && (quizparty >= quizdream)){
            $('#quizparty').show();
          } else if ((quizdream >= quizplay) && (quizdream >= quizparty)){
            $('#quizdream').show();
          }
          $($(this).attr('href')).fadeIn();
        }
        else if ($(this).parent().find("input[value='dream']").is(':checked')){
          quizdream++;
          $(this).parent().hide();
          if ((quizplay >= quizdream) && (quizplay >= quizparty)){
            $('#quizplay').show();
          } else if ((quizparty >= quizplay) && (quizparty >= quizdream)){
            $('#quizparty').show();
          } else if ((quizdream >= quizplay) && (quizdream >= quizparty)){
            $('#quizdream').show();
          }
          $($(this).attr('href')).fadeIn();
        } else {
          $(this).addClass('shake').delay(500).queue(function(){
            $(this).removeClass('shake').dequeue(); 
          });
        }
      } else {
        if ($(this).parent().find("input[value='party']").is(':checked')){
          quizparty++;
          $(this).parent().hide();
          $($(this).attr('href')).fadeIn();
        }
        else if ($(this).parent().find("input[value='play']").is(':checked')){
          quizplay++;
          $(this).parent().hide();
          $($(this).attr('href')).fadeIn();
        }
        else if ($(this).parent().find("input[value='dream']").is(':checked')){
          quizdream++;
          $(this).parent().hide();
          $($(this).attr('href')).fadeIn();
        } else {
          $(this).addClass('shake').delay(500).queue(function(){
            $(this).removeClass('shake').dequeue(); 
          });
        }
      }
    })

    $.ajax( {
      url: 'https://rest.bandsintown.com/artists/id_13302899/events?app_id=45PRESS_loloz',
      method: 'GET',
      dataType: 'json',
      error: () => {
      },
      success: data => {
        const events = $( '#shows' );
        let html = '<div class="event"><div><div class="event-date">Get updates on new shows, new music, and more.</div></div><div class="event-links"><iframe src="https://www.bandsintown.com/artist/id_13302899/track_button?size=large&display_tracker_count=false&text_color=%23FFFFFF&background_color=%23E987B6&hover_color=%23888888" height="32" width="92" scrolling="no" frameborder="0" style="border:none; overflow:hidden;" allowtransparency="true" ></iframe></div></div>';
        console.log( data );
        if ( data.length ) {
          for ( let event of data ) {
            let region = event.venue.region?event.venue.region:event.venue.country;
            const location = event.venue.city + ', ' + region;
            html += '<div class="event">';
            html += '<div class="event-left"><div class="event-date">' + momemt( event.datetime ).format( 'MMMM D' ) + '</div>';
            html += '<div class="event-location">' + location + '</div><div class="event-venue">' + event.venue.name + '</div></div>';
            html += '<div class="event-links">';
            for ( let offer of event.offers ) {
              html += '<a href="' + offer.url + '" target="_blank" class="btn"><span>' + offer.type + '</span></a>';
            }
            html += '</div>';
            html += '</div>';
          }
          events.html( html );
        } else {
          html+='<div class="event no-event">No upcoming events.</div>';
          events.html( html );
        }
      },
      error: console.log('Error fetching')
    } );

  //   //Apple Form
  //   $('.btn-apple').on('click', function( e ) { 
  //     var sm = new SMEAppleMusic({
  //       ae: {
  //         ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
  //         brand_id: "3807256",
  //         segment_id: "1638695",
  //         activities: '{"actions":{"presave":96763},"mailing_list_optins":{"a0S1p00000Slt9mEAB":96764}}'
  //       },
  //       am: {
  //         dev_token: "",
  //         save_mode: "library",
  //         custom_playlist_name: "",
  //         new_apple_presave: true,
  //         resources: {
  //           playlists: [],
  //           albums: []
  //         }
  //       },
  //       sf: {
  //         form: "212403",
  //         default_mailing_list: ""
  //       },
  //       smf: {
  //         campaign_id: "212403",
  //         campaign_key: "04dcc2da82e4c3d6c245bba3adfdc505"
  //       }
  //     });
  //     document.getElementById("apple_music_form").addEventListener("submit", function (e) {
  //       e.preventDefault();
  //       // Grab the email address value.
  //       var email = document.getElementById("apple_music_email_address").value;
  //       // Pass the email address and opt in status
  //       // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
  //       sm.doActions(email, false, ["a0S1p00000Slt9mEAB"]).then(function (res) {

  //               // Complete - handle your logic here
                
  //               $("#apple-popup").addClass('popup-hide');
  //               $("#presave").addClass('popup-hide');
  //               $("#contest").removeClass('popup-hide');
  //               $("#thankyou").removeClass('popup-hide');
  //               $("#screen>.icon").removeClass('hide');
                
  //           }).catch(function(err) {
  //               // Error - handle your logic here
  //           });
  //       });
  //   } );

  //   //Spotify
  //   let SMEPreSave = {
  //       state: null,
  //       spotify_user: null,
  //       url_vars: [],
  //       getURLVars: function (callback, form_id) {
  //         let hash;
  //         let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  //         for (let i = 0; i < hashes.length; i++) {
  //           hash = hashes[i].split('=');
  //           this.url_vars.push(hash[0]);
  //           this.url_vars[hash[0]] = hash[1];
  //             }
  //         this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
  //         this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
  //         this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
  //         this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
  //         this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
  //         if (typeof callback === 'function') {
  //           callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
  //         }
  //       }
  //     };
  //     /* String state
  //       *  The status of the pre-save.
  //       *  Int ae_member_id
  //       *  The Appreciation Engine member ID. This can be passed to the forms processor.
  //       *  String spotify_user
  //       *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
  //       *  String deezery_user
  //       *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
  //       */
  //     SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
  //       // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
  //       if (state === 'success' || state === 'thank-you') {
  //           $("#contest").removeClass('popup-hide');
  //           $("#thankyou").removeClass('popup-hide');
  //           $("#screen>.icon").removeClass('hide');
  //           showpopup=false;
  //       }
  //   });


  // function convertDateForIos(date) {
  //     var arr = date.split(/[- :]/);
  //     date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  //     return date;
  // }
  //     // Set the date we're counting down to
  // var countDownDate = new Date("2022-10-14T00:00:00.000-05:00").getTime();

  // // Update the count down every 1 second
  // var x = setInterval(function() {

  //   // Get today's date and time
  //   var now = new Date().getTime();

  //   // Find the distance between now and the count down date
  //   var distance = countDownDate - now;

  //   // Time calculations for days, hours, minutes and seconds
  //   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //   var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   document.getElementById("r-days").innerHTML = String(days).padStart(2,'0');
  //   document.getElementById("r-hours").innerHTML = String(hours).padStart(2,'0');
  //   document.getElementById("r-minutes").innerHTML = String(minutes).padStart(2,'0');
  //   document.getElementById("r-seconds").innerHTML = String(seconds).padStart(2,'0');

  //   // If the count down is finished, write some text
  //   if (distance < 0) {
  //     clearInterval(x);
  //     document.getElementById("countdown").style.display='none';
  //   }
  // }, 1000);
});